@import "core/index.scss";
.page.campaigns {
	padding: 0 0 4rem;

	.campaigns-list {
		display: flex;
		width: calc(100% + 2rem);
		margin: -1rem;
		flex-wrap: wrap;

		@include media {
			display: block;
		}
	}

		.list-campaign {
			padding: 1rem;
			width: 25%;
			display: flex;

			@include media {
				width: 100%;
			}
		}

			.campaign-button {
				display: flex;
				flex-direction: column;
				width: 100%;
				border: .1rem solid $color-gray-light;
				padding: 2rem;
				border-radius: $radius-general;
				box-shadow: $shadow-general;
				transition: background-color $transition-hover ease;

				@include mediaMin {
					&:hover {
						background-color: $color-bg-light;
					}
				}
			}

			.campaign-title {
				display: block;
				color: $color-pink;
				margin-bottom: 1rem;
			}

			.campaign-description {
				font-size: 1.4rem;
				line-height: 1.3em;
				color: $color-text-light;
				max-height: 3.9em;
				overflow: hidden;
				flex: 1 0 0;
			}

			.campaign-imagewrap {
				position: relative;
				padding-bottom: 44%;
				margin-bottom: 2rem;
				background-color: $color-bg;
				border-radius: $radius-general;
				overflow: hidden;
			}

			.campaign-image {
				position: absolute;
				width: 100%;
				height: 100%;
			}

			.campaign-ctawrap {
				@include font-medium;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 2rem;

				i {
					margin-left: 1rem;
				}
			}
}